<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { RouterView } from 'vue-router'
import { onMounted } from 'vue'
import { useDeviceManagementStore } from '@nsftx/seven-client-retail-vue-dm'
import { httpLogPlugin, logService } from '@nsftx/seven-client-retail-utils'
import { getAppRunUuid } from '@/utils/appRunUuidHelper'
import STHeader from './STHeader.vue'
import STFooter from './footer/STFooter.vue'

const LOG_PREFIX = '[SSBT]'
const dmStore = useDeviceManagementStore()
const { isDmReady } = storeToRefs(dmStore)

console.info('idDmReady', isDmReady)

onMounted(async () => {
  try {
    const appRunUuid = getAppRunUuid()

    if (!appRunUuid) {
      logService.error(`${LOG_PREFIX} App run uuid is not defined`, {
        code: 'ST_APP_RUN_UUID_UNDEFINED'
      })
      return
    }

    await dmStore.initDeviceManagement('7Terminal0')
    console.info(`%c${LOG_PREFIX} DM successfully initialized`, 'color:green;font-size:16px;')

    const appData = await dmStore.getAppData(appRunUuid)
    logService.info(`${LOG_PREFIX} App data received`, {
      appRunUuid,
      betshop: appData.betshop,
      device: appData.device,
      sevenUrl: appData.appConfig.data.sevenUrl,
      sevenSocketUrl: appData.appConfig.data.sevenSocketUrl,
      code: 'ST_DM_APP_DATA_RECEIVED'
    })
    httpLogPlugin.setAdditionalLogData({
      app_run_uuid: appRunUuid,
      tenant_id: appData.betshop.companyUuid,
      tenant_name: appData.betshop.companyName,
      device_id: appData.device.uuid,
      betshop_id: appData.betshop.uuid
    })
  } catch (error) {
    console.error(error)
  }
})
</script>

<template>
  <div class="st-app">
    <STHeader />
    <RouterView />
    <STFooter />
  </div>
</template>

<style>
.st-app {
  --st-app-bar-height: 64px;

  height: 100vh;
  display: grid;
  grid-template-rows: var(--st-app-bar-height) auto var(--st-app-bar-height);
}
</style>
