import { vuePlugin } from '@nsftx/seven-client-retail-vue-dm'
import { logService, httpLogPlugin, prefixLogPlugin } from '@nsftx/seven-client-retail-utils'
import { SevenComponentsPlugin } from '@nsftx/seven-components'
import translationsPlugin from './plugins/translationsPlugin'

import './assets/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './ui/components/App.vue'
import router from './router'

const urlParams = new URLSearchParams(window.location.search)
const urlLogLevel = urlParams.get('logLevel')
const lsLogLevel = localStorage.getItem('st.logLevel')

if (import.meta.env.PROD) {
  // set log level -> query param -> local storage -> default INFO
  logService.setLogLevel(urlLogLevel?.toUpperCase() || lsLogLevel?.toUpperCase() || 'INFO')
  // register http plugin that sends logs to grape
  logService.registerPlugin(httpLogPlugin.apply, {
    apiUrl: import.meta.env.VITE_GRAPE_API_URL,
    user: import.meta.env.VITE_GRAPE_API_AUTH_USER,
    pass: import.meta.env.VITE_GRAPE_API_AUTH_PASS,
    appName: 'SSBT',
    channel: 'SSBT' // so grape accepts request, change later to ssbt
  })
}
// register log prefix plugin (always)
logService.registerPlugin(prefixLogPlugin.apply)

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(vuePlugin)
app.use(translationsPlugin)
app.use(SevenComponentsPlugin, { SCKeyboard: { bottom: '64px' } })

app.mount('#app')
