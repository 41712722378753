<script setup lang="ts">
import { useDeviceManagementStore } from '@nsftx/seven-client-retail-vue-dm'
import { storeToRefs } from 'pinia'

const { appData } = storeToRefs(useDeviceManagementStore())
const commitHash = import.meta.env.VITE_COMMIT_HASH
</script>

<template>
  <div v-if="appData" class="st-device-info sc-text-caption-2 sc-text-primary-1 st-h-100 sc-mx-5">
    <div><span class="st-text-bold">V:</span> {{ commitHash }}</div>
    <div><span class="st-text-bold">BID:</span> {{ appData.betshop.name }}</div>
    <div><span class="st-text-bold">TID:</span> {{ appData.device.name }}</div>
  </div>
</template>

<style>
.st-device-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
