<script setup lang="ts">
import { onUnmounted, ref } from 'vue'
import { ScanService, logService } from '@nsftx/seven-client-retail-utils'
import { SCButton, ButtonVariant, SCRadioButton } from '@nsftx/seven-components'

const themes = ['default', 'yellow-pionir', 'yellow-balkan-bet']

const scanResult = ref<string>('')
const selectedTheme = ref<string>(themes[0])

const scan = ScanService()
scan.listenForScannerEvents((res) => {
  scanResult.value = res.data.code
})

const onThemeSelected = (theme: string) => {
  selectedTheme.value = theme
  document.documentElement.dataset.scPalette = theme
  logService.info('Theme changed', {
    details: {
      theme
    },
    code: 'ST_THEME_CHANGED'
  })
}

onUnmounted(() => {
  scan.stopListeningForScannerEvents()
})
</script>

<template>
  <main style="padding: 20px">
    <p>Instructions:</p>
    <ul>
      <li>Connect scanner</li>
      <li>Scan barcode</li>
    </ul>
    <p>Result: {{ scanResult }}</p>
    <h2>Choose theme:</h2>
    <SCRadioButton
      v-for="(theme, index) in themes"
      :key="index"
      :selected-value="selectedTheme"
      :value="theme"
      :label="theme"
      class="sc-mb-2"
      @update:model-value="onThemeSelected"
    />
    <h2>Button looks different on every theme:</h2>
    <p>Currently selected theme: {{ selectedTheme }}</p>
    <SCButton :variant="ButtonVariant.Primary"> Test Button </SCButton>
  </main>
</template>
