import { isRef, nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import enUS from '../locales/en-US.json'

type MessageSchema = typeof enUS

const i18n = createI18n<[MessageSchema], 'en-US'>({
  legacy: false, // use Composition API
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en-US',
  messages: {
    'en-US': enUS
  }
})

const setI18nLanguage = (locale: string) => {
  if (isRef(i18n.global.locale)) {
    i18n.global.locale.value = locale
  }
}

function setTranslations(translations: MessageSchema, locale = 'en') {
  i18n.global.setLocaleMessage(locale, translations)
  setI18nLanguage(locale)
  return nextTick()
}

export default i18n
export { setTranslations }
